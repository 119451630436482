// ujjwal work Start
export const CostEstimation_LIST_REQUEST = 'CostEstimation_LIST_REQUEST';
export const CostEstimation_LIST_SUCCESS = 'CostEstimation_LIST_SUCCESS';
export const CostEstimation_LIST_FAIL = 'CostEstimation_LIST_FAIL';

export const CostEstimation_SAVE_REQUEST = 'CostEstimation_SAVE_REQUEST';
export const CostEstimation_SAVE_SUCCESS = 'CostEstimation_SAVE_SUCCESS';
export const CostEstimation_SAVE_FAIL = 'CostEstimation_SAVE_FAIL';

export const PreAuth_LIST_REQUEST = 'PreAuth_LIST_REQUEST';
export const PreAuth_LIST_SUCCESS = 'PreAuth_LIST_SUCCESS';
export const PreAuth_LIST_FAIL = 'PreAuth_LIST_FAIL';

export const PreAuth_SAVE_REQUEST = 'PreAuth_SAVE_REQUEST';
export const PreAuth_SAVE_SUCCESS = 'PreAuth_SAVE_SUCCESS';
export const PreAuth_SAVE_FAIL = 'PreAuth_SAVE_FAIL';

export const PreAuth_CANCEL_REQUEST = 'PreAuth_CANCEL_REQUEST';
export const PreAuth_CANCEL_SUCCESS = 'PreAuth_CANCEL_SUCCESS';
export const PreAuth_CANCEL_FAIL = 'PreAuth_CANCEL_FAIL';

export const PreAuthQuery_SAVE_REQUEST = 'PreAuthQuery_SAVE_REQUEST';
export const PreAuthQuery_SAVE_SUCCESS = 'PreAuthQuery_SAVE_SUCCESS';
export const PreAuthQuery_SAVE_FAIL = 'PreAuthQuery_SAVE_FAIL';

export const PreAuthCommunication_SAVE_REQUEST = 'PreAuthCommunication_SAVE_REQUEST';
export const PreAuthCommunication_SAVE_SUCCESS = 'PreAuthCommunication_SAVE_SUCCESS';
export const PreAuthCommunication_SAVE_FAIL = 'PreAuthCommunication_SAVE_FAIL';

export const PreAuth_CheckList_LIST_REQUEST = 'PreAuth_CheckList_LIST_REQUEST';
export const PreAuth_CheckList_LIST_SUCCESS = 'PreAuth_CheckList_LIST_SUCCESS';
export const PreAuth_CheckList_LIST_FAIL = 'PreAuth_CheckList_LIST_FAIL';

export const Admission_LIST_REQUEST = 'Admission_LIST_REQUEST';
export const Admission_LIST_SUCCESS = 'Admission_LIST_SUCCESS';
export const Admission_LIST_FAIL = 'Admission_LIST_FAIL';

export const Admission_SAVE_REQUEST = 'Admission_SAVE_REQUEST';
export const Admission_SAVE_SUCCESS = 'Admission_SAVE_SUCCESS';
export const Admission_SAVE_FAIL = 'Admission_SAVE_FAIL';

// ujjwal work end

//Rajesh Work Start
export const AdmissionRequest_LIST_REQUEST = 'AdmissionRequest_LIST_REQUEST';
export const AdmissionRequest_LIST_SUCCESS = 'AdmissionRequest_LIST_SUCCESS';
export const AdmissionRequest_LIST_FAIL = 'AdmissionRequest_LIST_FAIL';

export const AdmissionRequest_GET_REQUEST = 'AdmissionRequest_GET_REQUEST';
export const AdmissionRequest_GET_SUCCESS = 'AdmissionRequest_GET_SUCCESS';
export const AdmissionRequest_GET_FAIL = 'AdmissionRequest_GET_FAIL';

export const AdmissionRequest_SAVE_REQUEST = 'AdmissionRequest_SAVE_REQUEST';
export const AdmissionRequest_SAVE_SUCCESS = 'AdmissionRequest_SAVE_SUCCESS';
export const AdmissionRequest_SAVE_FAIL = 'AdmissionRequest_SAVE_FAIL';

export const Encounter_LIST_REQUEST = 'Encounter_LIST_REQUEST';
export const Encounter_LIST_SUCCESS = 'Encounter_LIST_SUCCESS';
export const Encounter_LIST_FAIL = 'Encounter_LIST_FAIL';

export const ERVisitDetails_LIST_REQUEST = 'ERVisitDetails_LIST_REQUEST';
export const ERVisitDetails_LIST_SUCCESS = 'ERVisitDetails_LIST_SUCCESS';
export const ERVisitDetails_LIST_FAIL = 'ERVisitDetails_LIST_FAIL';

export const ERBedOCCUP_LIST_REQUEST = 'ERBedOCCUP_LIST_REQUEST';
export const ERBedOCCUP_LIST_SUCCESS = 'ERBedOCCUP_LIST_SUCCESS';
export const ERBedOCCUP_LIST_FAIL = 'ERBedOCCUP_LIST_FAIL';

export const ERAllPatientDetails_LIST_REQUEST = 'ERAllPatientDetails_LIST_REQUEST';
export const ERAllPatientDetails_LIST_SUCCESS = 'ERAllPatientDetails_LIST_SUCCESS';
export const ERAllPatientDetails_LIST_FAIL = 'ERAllPatientDetails_LIST_FAIL';

export const ERPatientDetails_LIST_REQUEST = 'ERPatientDetails_LIST_REQUEST';
export const ERPatientDetails_LIST_SUCCESS = 'ERPatientDetails_LIST_SUCCESS';
export const ERPatientDetails_LIST_FAIL = 'ERPatientDetails_LIST_FAIL';

export const Encounter_SAVE_REQUEST = 'Encounter_SAVE_REQUEST';
export const Encounter_SAVE_SUCCESS = 'Encounter_SAVE_SUCCESS';
export const Encounter_SAVE_FAIL = 'Encounter_SAVE_FAIL';

export const BedBook_LIST_REQUEST = 'BedBook_LIST_REQUEST';
export const BedBook_LIST_SUCCESS = 'BedBook_LIST_SUCCESS';
export const BedBook_LIST_FAIL = 'BedBook_LIST_FAIL';

export const BedBook_GET_REQUEST = 'BedBook_GET_REQUEST';
export const BedBook_GET_SUCCESS = 'BedBook_GET_SUCCESS';
export const BedBook_GET_FAIL = 'BedBook_GET_FAIL';

export const BedBook_SAVE_REQUEST = 'BedBook_SAVE_REQUEST';
export const BedBook_SAVE_SUCCESS = 'BedBook_SAVE_SUCCESS';
export const BedBook_SAVE_FAIL = 'BedBook_SAVE_FAIL';
export const PatientTransfer_LIST_REQUEST = 'PatientTransfer_LIST_REQUEST';
export const PatientTransfer_LIST_SUCCESS = 'PatientTransfer_LIST_SUCCESS';
export const PatientTransfer_LIST_FAIL = 'PatientTransfer_LIST_FAIL';

export const PatientTransfer_GET_REQUEST = 'PatientTransfer_GET_REQUEST';
export const PatientTransfer_GET_SUCCESS = 'PatientTransfer_GET_SUCCESS';
export const PatientTransfer_GET_FAIL = 'PatientTransfer_GET_FAIL';

export const PatientTransfer_SAVE_REQUEST = 'PatientTransfer_SAVE_REQUEST';
export const PatientTransfer_SAVE_SUCCESS = 'PatientTransfer_SAVE_SUCCESS';
export const PatientTransfer_SAVE_FAIL = 'PatientTransfer_SAVE_FAIL';

export const PractitionerTransfer_LIST_REQUEST = 'PractitionerTransfer_LIST_REQUEST';
export const PractitionerTransfer_LIST_SUCCESS = 'PractitionerTransfer_LIST_SUCCESS';
export const PractitionerTransfer_LIST_FAIL = 'PractitionerTransfer_LIST_FAIL';

export const PractitionerTransfer_GET_REQUEST = 'PractitionerTransfer_GET_REQUEST';
export const PractitionerTransfer_GET_SUCCESS = 'PractitionerTransfer_GET_SUCCESS';
export const PractitionerTransfer_GET_FAIL = 'PractitionerTransfer_GET_FAIL';


export const PractitionerTransfer_SAVE_REQUEST = 'PractitionerTransfer_SAVE_REQUEST';
export const PractitionerTransfer_SAVE_SUCCESS = 'PractitionerTransfer_SAVE_SUCCESS';
export const PractitionerTransfer_SAVE_FAIL = 'PractitionerTransfer_SAVE_FAIL';


export const PatientTransferRequest_LIST_REQUEST = 'PatientTransferRequest_LIST_REQUEST';
export const PatientTransferRequest_LIST_SUCCESS = 'PatientTransferRequest_LIST_SUCCESS';
export const PatientTransferRequest_LIST_FAIL = 'PatientTransferRequest_LIST_FAIL';

export const PatientTransferRequest_GET_REQUEST = 'PatientTransferRequest_GET_REQUEST';
export const PatientTransferRequest_GET_SUCCESS = 'PatientTransferRequest_GET_SUCCESS';
export const PatientTransferRequest_GET_FAIL = 'PatientTransferRequest_GET_FAIL';

export const PatientTransferRequest_SAVE_REQUEST = 'PatientTransferRequest_SAVE_REQUEST';
export const PatientTransferRequest_SAVE_SUCCESS = 'PatientTransferRequest_SAVE_SUCCESS';
export const PatientTransferRequest_SAVE_FAIL = 'PatientTransferRequest_SAVE_FAIL';

export const VisitorPass_LIST_REQUEST = 'VisitorPass_LIST_REQUEST';
export const VisitorPass_LIST_SUCCESS = 'VisitorPass_LIST_SUCCESS';
export const VisitorPass_LIST_FAIL = 'VisitorPass_LIST_FAIL';

export const VisitorPass_GET_REQUEST = 'VisitorPass_GET_REQUEST';
export const VisitorPass_GET_SUCCESS = 'VisitorPass_GET_SUCCESS';
export const VisitorPass_GET_FAIL = 'VisitorPass_GET_FAIL';

export const VisitorPass_SAVE_REQUEST = 'VisitorPass_SAVE_REQUEST';
export const VisitorPass_SAVE_SUCCESS = 'VisitorPass_SAVE_SUCCESS';
export const VisitorPass_SAVE_FAIL = 'VisitorPass_SAVE_FAIL';


//Rajesh Work End

//sart Dhiraj
export const Get_Admission_LIST_REQUEST = 'Get_Admission_LIST_REQUEST';
export const Get_Admission_LIST_SUCCESS = 'Get_Admission_LIST_SUCCESS';
export const Get_Admission_LIST_FAIL = 'Get_Admission_LIST_FAIL';

export const Get_Admission_SAVE_REQUEST = 'Get_Admission_SAVE_REQUEST';
export const Get_Admission_SAVE_SUCCESS = 'Get_Admission_SAVE_SUCCESS';
export const Get_Admission_SAVE_FAIL = 'Get_Admission_SAVE_FAIL';
//end dhiraj
export const RetainBed_SAVE_REQUEST = 'RetainBed_SAVE_REQUEST';
export const RetainBed_SAVE_SUCCESS = 'RetainBed_SAVE_SUCCESS';
export const RetainBed_SAVE_FAIL = 'RetainBed_SAVE_FAIL';

export const BedTimeline_SAVE_REQUEST = 'BedTimeline_SAVE_REQUEST';
export const BedTimeline_SAVE_SUCCESS = 'BedTimeline_SAVE_SUCCESS';
export const BedTimeline_SAVE_FAIL = 'BedTimeline_SAVE_FAIL';



//////////////// Start Amarjeet /////////////////////////////////////////////////////////////////////////////////////////////

export const DischargePatient_LIST_REQUEST = 'DischargePatient_LIST_REQUEST';
export const DischargePatient_LIST_SUCCESS = 'DischargePatient_LIST_SUCCESS';
export const DischargePatient_LIST_FAIL = 'DischargePatient_LIST_FAIL';


//////////////// End Amarjeet /////////////////////////////////////////////////////////////////////////////////////////////

export const AdmissionRequest_CANCEL_REQUEST = 'AdmissionRequest_CANCEL_REQUEST';
export const AdmissionRequest_CANCEL_SUCCESS = 'AdmissionRequest_CANCEL_SUCCESS';
export const AdmissionRequest_CANCEL_FAIL = 'AdmissionRequest_CANCEL_FAIL';

export const BedBook_CANCEL_REQUEST = 'BedBook_CANCEL_REQUEST';
export const BedBook_CANCEL_SUCCESS = 'BedBook_CANCEL_SUCCESS';
export const BedBook_CANCEL_FAIL = 'BedBook_CANCEL_FAIL';

export const Admission_CANCEL_REQUEST = 'Admission_CANCEL_REQUEST';
export const Admission_CANCEL_SUCCESS = 'Admission_CANCEL_SUCCESS';
export const Admission_CANCEL_FAIL = 'Admission_CANCEL_FAIL';

export const EstConfig_Template_SAVE_REQUEST = 'EstConfig_Template_SAVE_REQUEST';
export const EstConfig_Template_SAVE_SUCCESS = 'EstConfig_Template_SAVE_SUCCESS';
export const EstConfig_Template_SAVE_FAIL = 'EstConfig_Template_SAVE_FAIL';

export const EstConfig_BILLHEAD_SAVE_REQUEST = 'EstConfig_BILLHEAD_SAVE_REQUEST';
export const EstConfig_BILLHEAD_SAVE_SUCCESS = 'EstConfig_BILLHEAD_SAVE_SUCCESS';
export const EstConfig_BILLHEAD_SAVE_FAIL = 'EstConfig_BILLHEAD_SAVE_FAIL';

export const ESTIMATE_LIST_REQUEST = 'ESTIMATE_LIST_REQUEST';
export const ESTIMATE_LIST_SUCCESS = 'ESTIMATE_LIST_SUCCESS';
export const ESTIMATE_LIST_FAIL = 'ESTIMATE_LIST_FAIL';

export const ESTIMATE_TEMPALTEDETAILS_REQUEST = 'ESTIMATE_TEMPALTEDETAILS_REQUEST';
export const ESTIMATE_TEMPALTEDETAILS_SUCCESS = 'ESTIMATE_TEMPALTEDETAILS_SUCCESS';
export const ESTIMATE_TEMPALTEDETAILS_FAIL = 'ESTIMATE_TEMPALTEDETAILS_FAIL';

export const ESTIMATE_BILLHEADDETAILS_REQUEST = 'ESTIMATE_BILLHEADDETAILS_REQUEST';
export const ESTIMATE_BILLHEADDETAILS_SUCCESS = 'ESTIMATE_BILLHEADDETAILS_SUCCESS';
export const ESTIMATE_BILLHEADDETAILS_FAIL = 'ESTIMATE_BILLHEADDETAILS_FAIL';

export const ESTIMATE_BILLHEADDETAILS_BedCatgWise_REQUEST = 'ESTIMATE_BILLHEADDETAILS_BedCatgWise_REQUEST';
export const ESTIMATE_BILLHEADDETAILS_BedCatgWise_SUCCESS = 'ESTIMATE_BILLHEADDETAILS_BedCatgWise_SUCCESS';
export const ESTIMATE_BILLHEADDETAILS_BedCatgWise_FAIL = 'ESTIMATE_BILLHEADDETAILS_BedCatgWise_FAIL';

export const ESTIMATE_BILLHEADDETAILS_ServiceWise_REQUEST = 'ESTIMATE_BILLHEADDETAILS_ServiceWise_REQUEST';
export const ESTIMATE_BILLHEADDETAILS_ServiceWise_SUCCESS = 'ESTIMATE_BILLHEADDETAILS_ServiceWise_SUCCESS';
export const ESTIMATE_BILLHEADDETAILS_ServiceWise_FAIL = 'ESTIMATE_BILLHEADDETAILS_ServiceWise_FAIL';

export const ESTIMATE_GETDETAILS_REQUEST = 'ESTIMATE_GETDETAILS_REQUEST';
export const ESTIMATE_GETDETAILS_SUCCESS = 'ESTIMATE_GETDETAILS_SUCCESS';
export const ESTIMATE_GETDETAILS_FAIL = 'ESTIMATE_GETDETAILS_FAIL';

export const ESTIMATE_GETPAYORDETAILS_REQUEST = 'ESTIMATE_GETPAYORDETAILS_REQUEST';
export const ESTIMATE_GETPAYORDETAILS_SUCCESS = 'ESTIMATE_GETPAYORDETAILS_SUCCESS';
export const ESTIMATE_GETPAYORDETAILS_FAIL = 'ESTIMATE_GETPAYORDETAILS_FAIL';

export const ESTIMATE_SAVE_REQUEST = 'ESTIMATE_SAVE_REQUEST';
export const ESTIMATE_SAVE_SUCCESS = 'ESTIMATE_SAVE_SUCCESS';
export const ESTIMATE_SAVE_FAIL = 'ESTIMATE_SAVE_FAIL';

export const ESTIMATE_CANCEL_SAVE_REQUEST = 'ESTIMATE_CANCEL_SAVE_REQUEST';
export const ESTIMATE_CANCEL_SAVE_SUCCESS = 'ESTIMATE_CANCEL_SAVE_SUCCESS';
export const ESTIMATE_CANCEL_SAVE_FAIL = 'ESTIMATE_CANCEL_SAVE_FAIL';

export const FileUpload_LIST_REQUEST = 'FileUpload_LIST_REQUEST';
export const FileUpload_LIST_SUCCESS = 'FileUpload_LIST_SUCCESS';
export const FileUpload_LIST_FAIL = 'FileUpload_LIST_FAIL';

export const FileUpload_GET_REQUEST = 'FileUpload_GET_REQUEST';
export const FileUpload_GET_SUCCESS = 'FileUpload_GET_SUCCESS';
export const FileUpload_GET_FAIL = 'FileUpload_GET_FAIL';

export const FileUpload_SAVE_REQUEST = 'FileUpload_SAVE_REQUEST';
export const FileUpload_SAVE_SUCCESS = 'FileUpload_SAVE_SUCCESS';
export const FileUpload_SAVE_FAIL = 'FileUpload_SAVE_FAIL';

export const FaceSheet_LIST_REQUEST = 'FaceSheet_LIST_REQUEST';
export const FaceSheet_LIST_SUCCESS = 'FaceSheet_LIST_SUCCESS';
export const FaceSheet_LIST_FAIL = 'FaceSheet_LIST_FAIL';

export const FaceSheet_GET_REQUEST = 'FaceSheet_GET_REQUEST';
export const FaceSheet_GET_SUCCESS = 'FaceSheet_GET_SUCCESS';
export const FaceSheet_GET_FAIL = 'FaceSheet_GET_FAIL';

export const Facility_LIST_REQUEST = 'Facility_LIST_REQUEST';
export const Facility_LIST_SUCCESS = 'Facility_LIST_SUCCESS';
export const Facility_LIST_FAIL = 'Facility_LIST_FAIL';

export const Facility_GET_REQUEST = 'Facility_GET_REQUEST';
export const Facility_GET_SUCCESS = 'Facility_GET_SUCCESS';
export const Facility_GET_FAIL = 'Facility_GET_FAIL';

export const PrintVP_LIST_REQUEST = 'PrintVP_LIST_REQUEST';
export const PrintVP_LIST_SUCCESS = 'PrintVP_LIST_SUCCESS';
export const PrintVP_LIST_FAIL = 'PrintVP_LIST_FAIL';

export const PrintVP_GET_REQUEST = 'PrintVP_GET_REQUEST';
export const PrintVP_GET_SUCCESS = 'PrintVP_GET_SUCCESS';
export const PrintVP_GET_FAIL = 'PrintVP_GET_FAIL';

export const EREncounter_SAVE_REQUEST = 'EREncounter_SAVE_REQUEST';
export const EREncounter_SAVE_SUCCESS = 'EREncounter_SAVE_SUCCESS';
export const EREncounter_SAVE_FAIL = 'EREncounter_SAVE_FAIL';

export const RemarksDetail_LIST_REQUEST = 'RemarksDetail_LIST_REQUEST';
export const RemarksDetail_LIST_SUCCESS = 'RemarksDetail_LIST_SUCCESS';
export const RemarksDetail_LIST_FAIL = 'RemarksDetail_LIST_FAIL';

export const RemarksDetail_GET_REQUEST = 'RemarksDetail_GET_REQUEST';
export const RemarksDetail_GET_SUCCESS = 'RemarksDetail_GET_SUCCESS';
export const RemarksDetail_GET_FAIL = 'RemarksDetail_GET_FAIL';

export const CHECKLIST_LIST_REQUEST = 'CHECKLIST_LIST_REQUEST';
export const CHECKLIST_LIST_SUCCESS = 'CHECKLIST_LIST_SUCCESS';
export const CHECKLIST_LIST_FAIL = 'CHECKLIST_LIST_FAIL';

export const COMPULSARYSERVICE_LIST_REQUEST = 'COMPULSARYSERVICE_LIST_REQUEST';
export const COMPULSARYSERVICE_LIST_SUCCESS = 'COMPULSARYSERVICE_LIST_SUCCESS';
export const COMPULSARYSERVICE_LIST_FAIL = 'COMPULSARYSERVICE_LIST_FAIL';

export const EncounterADT_LIST_REQUEST = 'EncounterADT_LIST_REQUEST';
export const EncounterADT_LIST_SUCCESS = 'EncounterADT_LIST_SUCCESS';
export const EncounterADT_LIST_FAIL = 'EncounterADT_LIST_FAIL';

export const ERAllPatientlist_LIST_REQUEST = 'ERAllPatientlist_LIST_REQUEST';
export const ERAllPatientlist_LIST_SUCCESS = 'ERAllPatientlist_LIST_SUCCESS';
export const ERAllPatientlist_LIST_FAIL = 'ERAllPatientlist_LIST_FAIL';
