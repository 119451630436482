import { authenticationService } from '../_services';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser')) //authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
        // return { Authorization: `Bearer ${currentUser.token}` };
        return `Bearer ${currentUser.token}`;

    } else {
        return "No Auth";
    }
}