import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';

import { history, handleResponse } from '../_helpers';
//import { CoreMasterAPI, CoreBaseAPI } from '../../GlobalConfig';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const currentRolesSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('menuItems')));
const currentPageButtonsSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('pageButtons')));
const currentPageSectionButtonsSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('pageSectionButtons')));
const currentPageSectionSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('pageSections')));
const currentPageFunctionSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('pageFunction')));
const printerListSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('printerList')));

export const authenticationService = {
    login,
    logout,
    validateToken,
    saveMenuPinned,
    currentUser: currentUserSubject.asObservable(),
    currentRoles: currentRolesSubject.asObservable(),
    currentPageButtons: currentPageButtonsSubject.asObservable(),
    currentPageSectionButtons: currentPageSectionButtonsSubject.asObservable(),
    currentPrinter: printerListSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    get currentRolesValue() { return currentRolesSubject.value },
    get currentPageButtonsValue() { return currentPageButtonsSubject.value },
    get currentPageSectionButtonsValue() { return currentPageSectionButtonsSubject.value },
    get currentPageSectionValue() { return currentPageSectionSubject.value },
    get currentPageFunctionValue() { return currentPageFunctionSubject.value },
    get currentPrinterListValue() { return printerListSubject.value }
};

function login(Credentials) {

    let url = ""; //CoreMasterAPI + '/Employee/EmployeeLoginValidate';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(Credentials)
    };
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(userData => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(userData));
            currentUserSubject.next(userData);

            return userData;
        });
}

function logout() {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('loginDomain');
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('printerList');
    //clear all the localstorage data
    localStorage.clear();
    currentUserSubject.next(null);
    currentRolesSubject.next(null);
    currentPageButtonsSubject.next(null);
    currentPageSectionButtonsSubject.next(null);
    printerListSubject.next(null);
    // return <Redirect to={{ pathname: '/signin', state: { from: this.props.location } }} />
    window.location.href = '/signin'

}
function validateToken(apiPath, tokenData) {
    let url = apiPath + '/ValidateUserToken/ValidTokenUser';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(tokenData)
    };
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(userData => {
            // apply condition for invalid token
            return userData;
        });
}
function saveMenuPinned(apiurl, data) {
    let url = apiurl + '/ModulePages/SaveModulePagePinned';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(jsonData => {
            const data = JSON.stringify(jsonData.data);
            localStorage.setItem('pinnedMenu', data);
        });
}
