
export const GET_DIET_CATEGORY_REQUEST = 'GET_DIET_CATEGORY_REQUEST';
export const GET_DIET_CATEGORY_SUCCESS = 'GET_DIET_CATEGORY_SUCCESS';
export const GET_DIET_CATEGORY_FAIL = 'GET_DIET_CATEGORY_FAIL';

export const SAVE_DIET_CATEGORY_REQUEST = 'SAVE_DIET_CATEGORY_REQUEST';
export const SAVE_DIET_CATEGORY_SUCCESS = 'SAVE_DIET_CATEGORY_SUCCESS';
export const SAVE_DIET_CATEGORY_FAIL  = 'SAVE_DIET_CATEGORY_FAIL ';

export const GET_THERAPEUTIC_RESTRICTIONS_REQUEST = 'GET_THERAPEUTIC_RESTRICTIONS_REQUEST';
export const GET_THERAPEUTIC_RESTRICTIONS_SUCCESS = 'GET_THERAPEUTIC_RESTRICTIONS_SUCCESS';
export const GET_THERAPEUTIC_RESTRICTIONS_FAIL = 'GET_THERAPEUTIC_RESTRICTIONS_FAIL';

export const SAVE_THERAPEUTIC_RESTRICTIONS_REQUEST = 'SAVE_THERAPEUTIC_RESTRICTIONS_REQUEST';
export const SAVE_THERAPEUTIC_RESTRICTIONS_SUCCESS = 'SAVE_THERAPEUTIC_RESTRICTIONS_SUCCESS';
export const SAVE_THERAPEUTIC_RESTRICTIONS_FAIL = 'SAVE_THERAPEUTIC_RESTRICTIONS_FAIL';


export const GET_FOOD_HABITS_REQUEST = 'GET_FOOD_HABITS_REQUEST';
export const GET_FOOD_HABITS_SUCCESS = 'GET_FOOD_HABITS_SUCCESS';
export const GET_FOOD_HABITS_FAIL = 'GET_FOOD_HABITS_FAIL';


export const SAVE_FOOD_HABITS_REQUEST = 'SAVE_FOOD_HABITS_REQUEST';
export const SAVE_FOOD_HABITS_SUCCESS = 'SAVE_FOOD_HABITS_SUCCESS';
export const SAVE_FOOD_HABITS_FAIL  = 'SAVE_FOOD_HABITS_FAIL';

export const GET_CUISINE_REQUEST = 'GET_CUISINE_REQUEST';
export const GET_CUISINE_SUCCESS = 'GET_CUISINE_SUCCESS';
export const GET_CUISINE_FAIL = 'GET_CUISINE_FAIL';

export const SAVE_CUISINE_REQUEST = 'SAVE_CUISINE_REQUEST';
export const SAVE_CUISINE_SUCCESS = 'SAVE_CUISINE_SUCCESS';
export const SAVE_CUISINE_FAIL  = 'SAVE_CUISINE_FAIL';


export const GET_MEAL_REQUEST = 'GET_MEAL_REQUEST';
export const GET_MEAL_SUCCESS = 'GET_MEAL_SUCCESS';
export const GET_MEAL_FAIL = 'GET_MEAL_FAIL';

export const SAVE_MEAL_REQUEST = 'SAVE_MEAL_REQUEST';
export const SAVE_MEAL_SUCCESS = 'SAVE_MEAL_SUCCESS';
export const SAVE_MEAL_FAIL = 'SAVE_MEAL_FAIL';

export const GET_PAYOR_MEAL_MAPP_REQUEST = 'GET_PAYOR_MEAL_MAPP_REQUEST';
export const GET_PAYOR_MEAL_MAPP_SUCCESS = 'GET_PAYOR_MEAL_MAPP_SUCCESS';
export const GET_PAYOR_MEAL_MAPP_FAIL = 'GET_PAYOR_MEAL_MAPP_FAIL';

export const SAVE_PAYOR_MEAL_MAPP_REQUEST = 'SAVE_PAYOR_MEAL_MAPP_REQUEST';
export const SAVE_PAYOR_MEAL_MAPP_SUCCESS = 'SAVE_PAYOR_MEAL_MAPP_SUCCESS';
export const SAVE_PAYOR_MEAL_MAPP_FAIL = 'SAVE_PAYOR_MEAL_MAPP_FAIL';

export const GET_SEASON_REQUEST = 'GET_SEASON_REQUEST';
export const GET_SEASON_SUCCESS = 'GET_SEASON_SUCCESS';
export const GET_SEASON_FAIL = 'GET_SEASON_FAIL';

export const SAVE_SEASON_REQUEST = 'SAVE_SEASON_REQUEST';
export const SAVE_SEASON_SUCCESS = 'SAVE_SEASON_SUCCESS';
export const SAVE_SEASON_FAIL = 'SAVE_SEASON_FAIL';

export const GET_FOOD_CATEGORY_REQUEST = 'GET_FOOD_CATEGORY_REQUEST';
export const GET_FOOD_CATEGORY_SUCCESS = 'GET_FOOD_CATEGORY_SUCCESS';
export const GET_FOOD_CATEGORY_FAIL = 'GET_FOOD_CATEGORY_FAIL';

export const SAVE_FOOD_CATEGORY_REQUEST = 'SAVE_FOOD_CATEGORY_REQUEST';
export const SAVE_FOOD_CATEGORY_SUCCESS = 'SAVE_FOOD_CATEGORY_SUCCESS';
export const SAVE_FOOD_CATEGORY_FAIL = 'SAVE_FOOD_CATEGORY_FAIL';

export const GET_FOOD_ITEM_REQUEST = 'GET_FOOD_ITEM_REQUEST';
export const GET_FOOD_ITEM_SUCCESS = 'GET_FOOD_ITEM_SUCCESS';
export const GET_FOOD_ITEM_FAIL = 'GET_FOOD_ITEM_FAIL';

export const SAVE_FOOD_ITEM_REQUEST = 'SAVE_FOOD_ITEM_REQUEST';
export const SAVE_FOOD_ITEM_SUCCESS = 'SAVE_FOOD_ITEM_SUCCESS';
export const SAVE_FOOD_ITEM_FAIL = 'SAVE_FOOD_ITEM_FAIL';

export const GET_RT_LIQUID_FEED_REQUEST = 'GET_RT_LIQUID_FEED_REQUEST';
export const GET_RT_LIQUID_FEED_SUCCESS = 'GET_RT_LIQUID_FEED_SUCCESS';
export const GET_RT_LIQUID_FEED_FAIL = 'GET_RT_LIQUID_FEED_FAIL';

export const SAVE_RT_LIQUID_FEED_REQUEST = 'SAVE_RT_LIQUID_FEED_REQUEST';
export const SAVE_RT_LIQUID_FEED_SUCCESS = 'SAVE_RT_LIQUID_FEED_SUCCESS';
export const SAVE_RT_LIQUID_FEED_FAIL = 'SAVE_RT_LIQUID_FEED_FAIL';

export const GET_MENU_CYCLE_REQUEST = 'GET_MENU_CYCLE_REQUEST';
export const GET_MENU_CYCLE_SUCCESS = 'GET_MENU_CYCLE_SUCCESS';
export const GET_MENU_CYCLE_FAIL = 'GET_MENU_CYCLE_FAIL';

export const SAVE_MENU_CYCLE_REQUEST = 'SAVE_MENU_CYCLE_REQUEST';
export const SAVE_MENU_CYCLE_SUCCESS = 'SAVE_MENU_CYCLE_SUCCESS';
export const SAVE_MENU_CYCLE_FAIL = 'SAVE_MENU_CYCLE_FAIL';

export const GET_ITEM_OF_DAY_REQUEST = 'GET_ITEM_OF_DAY_REQUEST';
export const GET_ITEM_OF_DAY_SUCCESS = 'GET_ITEM_OF_DAY_SUCCESS';
export const GET_ITEM_OF_DAY_FAIL = 'GET_ITEM_OF_DAY_FAIL';

export const SAVE_ITEM_OF_DAY_REQUEST = 'SAVE_ITEM_OF_DAY_REQUEST';
export const SAVE_ITEM_OF_DAY_SUCCESS = 'SAVE_ITEM_OF_DAY_SUCCESS';
export const SAVE_ITEM_OF_DAY_FAIL = 'SAVE_ITEM_OF_DAY_FAIL';

export const GET_DIET_ORDER_DATA_REQUEST = 'GET_DIET_ORDER_DATA_REQUEST';
export const GET_DIET_ORDER_DATA_SUCCESS = 'GET_DIET_ORDER_DATA_SUCCESS';
export const GET_DIET_ORDER_DATA_FAIL = 'GET_DIET_ORDER_DATA_FAIL';

export const GET_DIET_ORDER_DATA_STATUS_UPDT_REQUEST = 'GET_DIET_ORDER_DATA_STATUS_UPDT_REQUEST';
export const GET_DIET_ORDER_DATA_STATUS_UPDT_SUCCESS = 'GET_DIET_ORDER_DATA_STATUS_UPDT_SUCCESS';
export const GET_DIET_ORDER_DATA_STATUS_UPDT_FAIL = 'GET_DIET_ORDER_DATA_STATUS_UPDT_FAIL';

export const GET_PREVIOUS_DIET_ORDER_LIST_REQUEST = 'GET_PREVIOUS_DIET_ORDER_LIST_REQUEST';
export const GET_PREVIOUS_DIET_ORDER_LIST_SUCCESS = 'GET_PREVIOUS_DIET_ORDER_LIST_SUCCESS';
export const GET_PREVIOUS_DIET_ORDER_LIST_FAIL = 'GET_PREVIOUS_DIET_ORDER_LIST_FAIL';

export const GET_PREVIOUS_DIET_MEAL_LIST_REQUEST = 'GET_PREVIOUS_DIET_MEAL_LIST_REQUEST';
export const GET_PREVIOUS_DIET_MEAL_LIST_SUCCESS = 'GET_PREVIOUS_DIET_MEAL_LIST_SUCCESS';
export const GET_PREVIOUS_DIET_MEAL_LIST_FAIL = 'GET_PREVIOUS_DIET_MEAL_LIST_FAIL';

export const GET_PREVIOUS_DIET_ORDER_REQUEST = 'GET_PREVIOUS_DIET_ORDER_REQUEST';
export const GET_PREVIOUS_DIET_ORDER_SUCCESS = 'GET_PREVIOUS_DIET_ORDER_SUCCESS';
export const GET_PREVIOUS_DIET_ORDER_FAIL = 'GET_PREVIOUS_DIET_ORDER_FAIL';

export const GET_DIET_ORDER_STATUS_REQUEST = 'GET_DIET_ORDER_STATUS_REQUEST';
export const GET_DIET_ORDER_STATUS_SUCCESS = 'GET_DIET_ORDER_STATUS_SUCCESS';
export const GET_DIET_ORDER_STATUS_FAIL = 'GET_DIET_ORDER_STATUS_FAIL';

export const GET_ADMITTED_PATIENT_LIST_REQUEST = 'GET_ADMITTED_PATIENT_LIST_REQUEST';
export const GET_ADMITTED_PATIENT_LIST_SUCCESS = 'GET_ADMITTED_PATIENT_LIST_SUCCESS';
export const GET_ADMITTED_PATIENT_LIST_FAIL = 'GET_ADMITTED_PATIENT_LIST_FAIL';

export const GET_Diet_Plan_ItemQuantitybybedcategory_REQUEST = 'GET_Diet_Plan_ItemQuantitybybedcategory_REQUEST';
export const GET_Diet_Plan_ItemQuantitybybedcategory_SUCCESS = 'GET_Diet_Plan_ItemQuantitybybedcategory_SUCCESS';
export const GET_Diet_Plan_ItemQuantitybybedcategory_FAIL = 'GET_Diet_Plan_ItemQuantitybybedcategory_FAIL';